import axios from "../../axios-config";

/**
 * 新增类别
 */
export function GoodsSpecController_post(params: any) {
  return axios.post("/api/back/mall/GoodsSpecController", { ...params });
}

/**
 * 修改类别
 */
export function GoodsSpecController_put(params: any) {
  return axios.put("/api/back/mall/GoodsSpecController", { ...params });
}

/**
 * 删除类别
 */
export function GoodsSpecController_del(params: any) {
  return axios.delete("/api/back/mall/GoodsSpecController", {
    params: {
      ...params
    }
  });
}
/**
 * 分页查询类别
 */
export function page(params: any) {
  return axios.get("/api/back/mall/GoodsSpecController/find", {
    params: {
      ...params
    }
  });
}
// 规格详情
/**
 * 分页查询类别
 */
export function GoodsSpecValueController_page(params: any) {
  return axios.get("/api/back/mall/GoodsSpecValueController/page", {
    params: {
      ...params
    }
  });
}
/**
 * 新增类别
 */
export function GoodsSpecValueController_post(params: any) {
  return axios.post("/api/back/mall/GoodsSpecValueController", { ...params });
}

/**
 * 修改类别
 */
export function GoodsSpecValueController_put(params: any) {
  return axios.put("/api/back/mall/GoodsSpecValueController", { ...params });
}

/**
 * 删除类别
 */
export function GoodsSpecValueController_del(params: any) {
  return axios.delete("/api/back/mall/GoodsSpecValueController", {
    params: {
      ...params
    }
  });
}