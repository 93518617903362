<template>
  <div>
    <a-modal width="500px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="规格值：" goodsSpecValue="goodsSpecValue">
          <a-input v-model:value="formState.goodsSpecValue" style="width: 370px" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import {
  GoodsSpecValueController_post,
  GoodsSpecValueController_put
} from '@/api/commodityManagement/goodsspec'
const form = {
  goodsSpecValue: '',
  goodsSpecId: ''
}
export default defineComponent({
  goodsSpecValue: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      pid: '',
      visible: false,
      formState: {
        ...form
      },
      rules: {
        goodsSpecValue: [
          { required: true, message: '不能为空', trigger: 'change' }
        ]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          let obj = {
            goodsSpecValue: this.formState.goodsSpecValue,
            goodsSpecId: this.pid
          }
          GoodsSpecValueController_post(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            // this.$parent.refresh();
            this.$emit('refresh')
          })
        } else {
          let obj = this.formState
          GoodsSpecValueController_put(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            // this.$parent.refresh();
            this.$emit('refresh')
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val.goodsSpecValue) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
        this.pid = val
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    